import {
  IEnvironment as BaseEnvironment,
  LogTypes,
} from '@yukawa/chain-base-angular-client';

export enum AppEnvironment {
  'admin' = 'admin',
  'app' = 'app',
}

export interface IEnvironment extends BaseEnvironment {
  app: AppEnvironment;
  buildNumber: string;
  buildVersion: string;
  logTypes: LogTypes[];
}

import { provideHttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    APP_INITIALIZER,
    ApplicationConfig,
    importProvidersFrom,
    inject,
} from '@angular/core';
import {
    APP_ENVIRONMENT_TOKEN,
    ConfigService,
    InitModule,
    InitService,
    ENVIRONMENT_TOKEN,
} from '@yukawa/chain-base-angular-client';
import { MEDIA_WATCHER_SERVICE } from '@yukawa/chain-base-angular-comp/shared';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { provideTransloco, TranslocoService } from '@ngneat/transloco';
import { Observable, firstValueFrom } from 'rxjs';
import { appRoutes } from 'app/app.routes';
import { provideAuth } from 'app/core/auth/auth.provider';
import { provideIcons } from 'app/core/icons/icons.provider';
import { mockApiServices } from 'app/mock-api';
import { TranslocoHttpLoader } from './core/transloco/transloco.http-loader';
import { environment } from '../environments';
import { SessionService } from '@yukawa/chain-main-angular-session';
import { QueryTableModule } from '@yukawa/chain-base-angular-comp/query-table';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        // Yukawa Environment
        {
            provide: APP_ENVIRONMENT_TOKEN,
            useValue: environment,
        },

        // Yukawa Config
        {
            provide: APP_INITIALIZER,
            useFactory: (myInitService: InitService) => (): Observable<void> =>
                myInitService.initConfigs(),
            deps: [InitService, ConfigService],
            multi: true,
        },
        provideAuth(),
        SessionService,
        importProvidersFrom(QueryTableModule.forRoot()),
        
        {
            provide: MEDIA_WATCHER_SERVICE,
            useClass: FuseMediaWatcherService,
        },

        // Material Date Adapter
        {
            provide: DateAdapter,
            useClass: LuxonDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'D',
                },
                display: {
                    dateInput: 'DDD',
                    monthYearLabel: 'LLL yyyy',
                    dateA11yLabel: 'DD',
                    monthYearA11yLabel: 'LLLL yyyy',
                },
            },
        },

        // Transloco Config
        provideTransloco({
            config: {
                availableLangs: [
                    {
                        id: 'en',
                        label: 'English',
                    },
                    {
                        id: 'de',
                        label: 'Deutsch',
                    },
                ],
                defaultLang: 'en',
                fallbackLang: 'en',
                reRenderOnLangChange: true,
                prodMode: true,
            },
            loader: TranslocoHttpLoader,
        }),
        {
            // Preload the default language before the app starts to prevent empty/jumping content
            provide: APP_INITIALIZER,
            useFactory: () => {
                const translocoService = inject(TranslocoService);
                const defaultLang = translocoService.getDefaultLang();
                translocoService.setActiveLang(defaultLang);

                return () => firstValueFrom(translocoService.load(defaultLang));
            },
            multi: true,
        },

        // Fuse
        //provideAuth(),
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'modern',
                scheme: 'dark',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-amber',
                themes: [],
            },
        }),
    ],
};

import { AppEnvironment, IEnvironment } from '../app/config';
import { LogTypes } from '@yukawa/chain-base-angular-client';

export const environment: IEnvironment = {
    app: AppEnvironment.admin,
    buildNumber: '0',
    buildVersion: '1.0.0',
    production: false,
    logTypes: [LogTypes.error, LogTypes.warn],
};
